import React from 'react';
import './BottomNav.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FooterLogo from './../../../assets/img/logo/White-logo-no-background.png';
import moment from 'moment';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const BottomNav = (props) => {
    return (
        <Container maxWidth={false} className="footer-ct">
            <Grid container className="footer-content-ct">
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <img src={FooterLogo} alt="Inoook" className="footer-logo" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            Be more productive.
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="footer-social-icons-row">
                            <a href="https://www.instagram.com/inoookapplication/" target="_blank" rel="noopener noreferrer" className="footer-social-icons-links"><InstagramIcon></InstagramIcon></a>
                            <a href="https://www.facebook.com/inoookapplication" target="_blank" rel="noopener noreferrer" className="footer-social-icons-links"><FacebookIcon></FacebookIcon></a>
                            <a href="https://twitter.com/team_inoook" target="_blank" rel="noopener noreferrer" className="footer-social-icons-links"><TwitterIcon></TwitterIcon></a>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="copyright-ct">
                            <div className="copyright-txt">&#169; {moment().year()} inoook.com</div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BottomNav;