import React from 'react';
import './TopNav.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import InoookHeaderLogo from './../../../assets/img/logo/Black-logo-no-background.png';
import Button from '@mui/material/Button';
import { isMobile, isTablet, isSmartTV, isWearable } from 'react-device-detect';


const TopNav = (props) => {

    const handleAppDownload = props.handleAppDownload;

    let downlodButton = null;
    if(isMobile || isTablet || isSmartTV || isWearable){
        downlodButton = <Button variant="contained" className="head-right-button" disabled>Download</Button>
    }else{
        downlodButton = <Button variant="contained" className="primary-red-color head-right-button" onClick={() => handleAppDownload()}>Download</Button>
    }

    return (
        <Container maxWidth={false} className="head-non-auth-ct">
            <Grid container className="topNavMainCt">
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Link to="/" className="head-logo-link"><img src={InoookHeaderLogo} className="inoook-head-logo" alt="Inoook"/></Link>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    {downlodButton}
                </Grid>
            </Grid>
        </Container>
    );
};

export default TopNav;