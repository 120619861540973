import React from 'react';
import './LandingPageCenterCt1.css';
import Grid from '@mui/material/Grid';
import TodoFullImage from './../../../../../assets/img/illustrations/todo-full.png';
import Button from '@mui/material/Button';
import Typed from 'react-typed';
import { isMobile, isTablet, isSmartTV, isWearable } from 'react-device-detect';


const LandingPageCenterCt1 = (props) => {

    const handleAppDownload = props.handleAppDownload;

    let displayImage = TodoFullImage;
    let typedHeading = <Typed
                            strings={['todos','notes']}
                            typeSpeed={40}
                            backSpeed={40}
                            loop
                        />;
    let displayHeading = <React.Fragment>
                            <span className="TextButtonImageTxtCtHeading1">
                                Manage your <span className="typed-txt">{typedHeading}</span>
                            </span> 
                            all in one place
                        </React.Fragment>;
    let displaySubHeading = 'Easily create new projects to manage all your related todos and notes. Try it, its free!';
    let displayButton = <Button variant="contained" className="primary-red-color TextButtonImageTxtCtButtonDesign" onClick={() => handleAppDownload()}>Download For Free</Button>;
    let displayButtonUnavailable = <Button variant="contained" className="TextButtonImageTxtCtButtonDesign" disabled onClick="">Download Unavailable</Button>;
    let displayButtonUnavailableTxt = 'Sorry, Inoook is only available for Mac/Windows at this time.';

    let downlodButton = null;
    if(isMobile || isTablet || isSmartTV || isWearable){
        downlodButton = <React.Fragment>
                                <div className="TextButtonImageTxtCtButton">{displayButtonUnavailable}</div>
                                <div className="TextButtonImageTxtCtButtonDisabledTxt1">{displayButtonUnavailableTxt}</div>
                        </React.Fragment>
    }else{
        downlodButton = <div className="TextButtonImageTxtCtButton">{displayButton}</div>
    }

    return (
        <div className="TextButtonImageMainCtBackground">
            <Grid container className="TextButtonImageMainCt">
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Grid container className="TextButtonImageTxtCt">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div className="TextButtonImageTxtCtHeading">{displayHeading}</div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div className="TextButtonImageTxtCtSubHeading">{displaySubHeading}</div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {downlodButton}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12} className="displayImageRulesPrimary">
                    <img src={displayImage} className="displayImageRules" alt="Todo Project"/>
                </Grid>
            </Grid>
        </div>
    )
};

export default LandingPageCenterCt1;