import React from 'react';
import './LandingPageCenterCt2.css';
import Grid from '@mui/material/Grid';
import CreateProjectsMobile from './../../../../../assets/img/illustrations/create-projects-mobile.png';
import CreateTodoMobile from './../../../../../assets/img/illustrations/todo-mobile.png';

const LandingPageCenterCt2 = (props) => {

    let displayImage = CreateProjectsMobile;
    let displayImageSecond = CreateTodoMobile;
    let displayHeading = 'Easily create new projects';
    let displaySubHeading = 'Manage your todos and notes by easily creating new projects.';

    return (
       <Grid container className="TextButtonImageMainCt2">
            <Grid item lg={8} md={8} sm={12} xs={12} className="ImgBlockCustom">
                <img src={displayImageSecond} className="displayImageRules ImgCustomStyle todoProjectsMobileImgCt2" alt="Todo Project"/>
                <img src={displayImage} className="displayImageRules ImgCustomStyle" alt="Create New Projects"/>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <Grid container className="TextButtonImageTxtCtRight">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="TextButtonImageTxtCtHeadingRight">{displayHeading}</div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="TextButtonImageTxtCtSubHeadingRight">{displaySubHeading}</div>
                    </Grid>
                </Grid>
            </Grid>
       </Grid>
    )
};

export default LandingPageCenterCt2;
