import React from 'react';
import './LandingPageCenterCt4.css';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { isMobile, isTablet, isSmartTV, isWearable } from 'react-device-detect';

const LandingPageCenterCt4 = (props) => {

    const handleAppDownload = props.handleAppDownload;

    let displayHeading = 'Stay on track of your goals and be more productive';
    let displayButton = <Button variant="outlined" className="LandingPageCenterCt4ButtonDesign" onClick={() => handleAppDownload()}>Download Inoook For Free</Button>;
    let displayButtonUnavailable = <Button variant="outlined" className="LandingPageCenterCt4DisabledButtonDesign" disabled onClick="">Download Unavailable</Button>;
    let displayButtonUnavailableTxt = 'Sorry, Inoook is only available for Mac/Windows at this time.';

    let downlodButton = null;
    if(isMobile || isTablet || isSmartTV || isWearable){
        downlodButton = <React.Fragment>
                                {displayButtonUnavailable}
                                <div className="TextButtonImageTxtCtButtonDisabledTxt1Ct4">{displayButtonUnavailableTxt}</div>
                        </React.Fragment>
    }else{
        downlodButton = displayButton
    }

    return (
        <Grid container className="landingPageCenterCt4Main">
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className=""><FlagCircleIcon className="TextButtonImageTxtCtHeadingCt4Icon"></FlagCircleIcon></div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="TextButtonImageTxtCtHeadingCt4">{displayHeading}</div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                {downlodButton}
            </Grid>
        </Grid>
    )
};

export default LandingPageCenterCt4;