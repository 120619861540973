import React from 'react';
import './LandingPageCenterCt3.css';
import Grid from '@mui/material/Grid';
import NotesFullImage from './../../../../../assets/img/illustrations/notes-full.png'

const LandingPageCenterCt3 = (props) => {

    let displayImage = NotesFullImage;

    let displayHeading = 'Take notes quickly';
    let displaySubHeading = 'Remember everything by taking notes quickly. Format your notes with a powerful rich text editor.';

    return (
        <Grid container className="TextButtonImageMainCt3">
            <Grid item lg={8} md={8} sm={12} xs={12} className="displayImageRulesMobile">
                <img src={displayImage} className="displayImageRules" alt="Notes Project"/>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <Grid container className="TextButtonImageTxtCtRight">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="TextButtonImageTxtCtHeadingRight">{displayHeading}</div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="TextButtonImageTxtCtSubHeadingRight">{displaySubHeading}</div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12} className="displayImageRulesPrimary">
                <img src={displayImage} className="displayImageRules" alt="Notes Project"/>
            </Grid>
        </Grid>
    )
};

export default LandingPageCenterCt3;
