import React from 'react';
import './LandingPage.css';
import TopNav from '../../../common/TopNav/TopNav';
import BottomNav from '../../../common/BottomNav/BottomNav';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LandingPageCenterCt1 from './LandingPageCenterCt1/LandingPageCenterCt1';
import LandingPageCenterCt2 from './LandingPageCenterCt2/LandingPageCenterCt2';
import LandingPageCenterCt3 from './LandingPageCenterCt3/LandingPageCenterCt3';
import LandingPageCenterCt4 from './LandingPageCenterCt4/LandingPageCenterCt4';
import emailjs from '@emailjs/browser';


const LandingPage = (props) => {
    const notifyAdmin = (platform) => {
        const templateParams = {
            platform: platform,
            date_time: new Date()
        };

        emailjs.send(process.env.REACT_APP_MAIL_SERVICE_ID, process.env.REACT_APP_MAIL_TEMPLATE_ID, templateParams, process.env.REACT_APP_MAIL_KEY)
            .then((response) => {
                console.log('Success.', response.status, response.text);
            }, (err) => {
                console.log('Failed.', err);
            });
    };

    const detectPlatform = () => {
        let userAgent = window.navigator.userAgent;
        let platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
        let macosPlatforms = ['macintosh', 'macintel', 'macppc', 'mac68k', 'macos'];
        let windowsPlatforms = ['win32', 'win64', 'windows', 'wince', 'win'];
        let iosPlatforms = ['iphone', 'ipad', 'ipod'];
        let os = null;

        if (macosPlatforms.indexOf(platform.toLowerCase()) !== -1) {
            os = 'mac_os';
          } else if (iosPlatforms.indexOf(platform.toLowerCase()) !== -1) {
            os = 'ios';
          } else if (windowsPlatforms.indexOf(platform.toLowerCase()) !== -1) {
            os = 'windows';
          } else if (/Android/.test(userAgent)) {
            os = 'android';
          } else if (/Linux/.test(platform)) {
            os = 'linux';
          }
          return os;
    };

    const downloadFile = (fileURL, fileName) => {
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', fileName);
        link.click();
    };

    const handleAppDownload = () => {
       const downloadPlatform = detectPlatform();

       const macOSDownloadLink = 'https://inoook-desktop-app.s3.amazonaws.com/Inoook-1.2.4.dmg';
       const windowsDownloadLink = 'https://inoook-desktop-app.s3.amazonaws.com/Inoook-Setup-1.2.4.exe';
       const savedFileName = 'Inoook-1.2.4';


       let inoookDesktopAppDownloadURL = null;
       let savedFileNameExtension = null;
       if(downloadPlatform === 'mac_os'){
            inoookDesktopAppDownloadURL = macOSDownloadLink;
            savedFileNameExtension = '.dmg';
       }else if(downloadPlatform === 'windows'){
            inoookDesktopAppDownloadURL = windowsDownloadLink;
            savedFileNameExtension = '.exe';
       }

       if(inoookDesktopAppDownloadURL.length > 0){
            downloadFile(inoookDesktopAppDownloadURL, savedFileName+''+savedFileNameExtension);
            notifyAdmin(downloadPlatform);
       }
    };

    return (
        <React.Fragment>
            <TopNav handleAppDownload={handleAppDownload}></TopNav>
            <Container maxWidth={false} className="landingPageCenterDiv">
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <LandingPageCenterCt1 handleAppDownload={handleAppDownload}/>
                        <LandingPageCenterCt2 />
                        <LandingPageCenterCt3 />
                        <LandingPageCenterCt4 handleAppDownload={handleAppDownload}/>
                    </Grid>
                </Grid>
            </Container>
            <BottomNav></BottomNav>
        </React.Fragment>
    );
};

export default LandingPage;